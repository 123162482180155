/** @format */

import Reward from './libs/reward';
import Data from './libs/data';
import Testing from './libs/testing';
import UserInfo from './libs/userInfo';
export const data = Data;
export const reward = Reward;
export const testing = Testing;
export const userInfo = UserInfo;
