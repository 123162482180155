/** @format */

import Vue from 'vue';
import App from '@/App.vue';
import router from './router';
import store from './store';
import config from './config/config';
import './libs/registerComponents';
import './libs/translations';
import './libs/axios';
import './libs/date';
import './libs/string';

import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  el: '#app',
  data() {
    return {
      width: 0,
      widthMobil: 450,
      showAD: false,
      canRefreshAD: true,
      start: false,
      prepare: false,
      showHelp: false,
      loaded: false,
      loadedG: false,
      loadedB: false,
      lang: this.$route.meta?.lang || 'ru',
      authData: null,
      adsId: 0,
      secret: 'DSFw#RSDGh54#$RDF',
      title1: 'Тест ПДД. JOLDO.KG',

      title: 'Тест ПДД. JOLDO.KG',
    };
  },
  computed: {
    config() {
      return config;
    },
    v2() {
      return true;
      return this.authData && this.authData.id && [36, 40].includes(this.authData.id);
    },
  },
  created() {
    this.getAuthData();
  },
  watch: {
    title(v) {
      document.title = v;
    },
    showAD(v) {
      if (v) {
        console.log('width', this.width);
        setTimeout(() => {
          (adsbygoogle = window.adsbygoogle || []).push({});
          setTimeout(() => {
            (adsbygoogle = window.adsbygoogle || []).push({});
          }, 500);
        }, 500);
      }
    },
  },
  methods: {
    getAuthData() {
      if (process.env.NODE_ENV === 'development') {
        console.log('development');
        this.authData = { id: 36 };
      } else
        fetch(this.$root.config.baseUrl + '/pdd/get_auth/', {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Ошибка при выполнении запроса');
            }
            return response.json();
          })
          .then((auth) => {
            this.authData = JSON.parse(auth || '{}') || {};
            // console.log('auth', this.authData);
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
});
