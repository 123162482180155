/** @format */
const secret = 'DSFw#RSDGh54#$RDF';
const crypto = require('crypto');

export default {
  data() {
    return {
      infoResult: null,
    };
  },
  created() {},
  destroyed() {},
  computed: {},
  methods: {
    async makeUserInfo() {
      if (!this.auth?.id) return;
      this.infoResult = null;
      let info;
      let resp = await fetch(this.$root.config.baseUrl + '/getUserInfo', {
        method: 'GET',
        credentials: 'include',
      });
      if (resp.ok) info = await resp.json();
      else {
        this.infoResult = 'Ошибка получения данных пользователя';
      }
      console.log('makeUserInfo', { info, type: this.type, ishard: this.isHard, results: this.results });
      if (this.type == 'topic') {
        const groupId = this.groupBilet?.id;
        let topics = JSON.parse((info.find((i) => i.name == 'topic') || {})?.data || '{}');
        const topic = topics?.[groupId] || {};
        console.log({ topic }, this.groupBilet);
        let done = 0,
          total = this.groupBilet.total;
        for (const i in this.results) {
          const r = this.results[i];
          if (r.type !== 2) {
            // total++;
            if (r.result == 1 && !r.useHelp) done++;
          }
        }
        let isPost = 0;
        let t = {};
        if ((topic?.['done'] || -1) < done || topic.total !== total) {
          isPost = 1;
          t['done'] = done;

          t['total'] = total;
          t['group_id'] = groupId;
          t['name'] = this.groups.find((g) => g.id == groupId)?.name || '???';
          this.infoResult = `Тема: "${t.name}". ${t.done} из ${t.total}`;
          topics[groupId] = t;
        }
        //console.log({ topics, topic, isPost });
        if (isPost) this.setUserInfo('topic', topics);
      }

      if (this.type == 'bilet') {
        console.log({ biletId: this.biletId, typeP: this.typeP }, this.typeP.join());
        if (!(this.biletId > 0 && this.typeP.join() == '1')) return;
        let topics = JSON.parse((info.find((i) => i.name == 'bilet') || {})?.data || '{}');
        const topic = topics?.[this.biletId] || {};
        console.log({ topic });
        let done = 0,
          total = 0;
        for (const i in this.results) {
          const r = this.results[i];
          if (r.type !== 2) {
            total++;
            if (r.result == 1 && !r.useHelp) done++;
          }
        }
        let isPost = 0;
        let t = {};
        if ((topic?.['done'] || -1) < done) {
          isPost = 1;
          t['done'] = done;
          t['total'] = total;
          t['name'] = 'Билет ' + this.biletId || '???';
          this.infoResult = `${t.name}. ${t.done} из ${t.total}`;
        }
        topics[this.biletId] = t;
        console.log({ topics, topic, isPost });
        if (isPost) this.setUserInfo('bilet', topics);
      }
    },
    async setUserInfo(n, d) {
      if (!n || !d) return;
      try {
        const cipher = crypto.createCipher('aes-256-cbc', this.$root.secret);
        let encryptedData;
        let dataString = JSON.stringify({ name: n, data: d });
        encryptedData = cipher.update(dataString, 'utf-8', 'hex');
        encryptedData += cipher.final('hex');
        const response = await fetch(this.$root.config.baseUrl + '/setUserInfo', {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: encryptedData }),
        });
        if (!response.ok) {
          console.error('Ошибка при отправки статистики');
          return false;
        }
      } catch (error) {
        console.error('Ошибка при отправки статистики', error);
        return false;
      }
      return true;
    },
  },
};
